<template>
  <div>
    <!-- 联系我们首图 -->
    <div class="contactBanner">
      <img src="@/assets/img/contact/banner_contactus.jpg" />
    </div>
    <!-- 地图 -->
    <div class="contactMap">
      <div class="contactMap_title">
        <div>contact</div>
        <div>
          <div>联系方式</div>
          <div class="columnTitle_bg">
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
      <div>
        <div class="contactMap_info">
          <div>
            <div><img src="@/assets/img/contact/pic-bontact-tel.png" /></div>
            <div>020-3203 9269</div>
          </div>
          <div>
            <div><img src="@/assets/img/contact/pic-bontact-phone.png" /></div>
            <div>19924720960</div>
          </div>
          <div>
            <div><img src="@/assets/img/contact/pic-bontact-wechat.png" /></div>
            <div>VLimYK</div>
          </div>
        </div>
        <div>
          <baidu-map class="map" :center="markerPoint" :zoom="30">
            <bm-marker
              :position="markerPoint"
              :dragging="true"
              @click="infoWindowOpen"
            >
              <bm-info-window
                :show="show"
                @close="infoWindowClose"
                @open="infoWindowOpen"
              >
                <div class="mapTips">
                  <div>广东微林软件有限公司</div>
                  <br />
                  <div>
                    广州市开发区科学城科研路12号生产力促进中心 205、215、216号
                  </div>
                </div>
              </bm-info-window>
            </bm-marker>
          </baidu-map>
        </div>
      </div>
    </div>
    <!-- 给我们留言 -->
    <!-- <div class="contactLeave">
      <div class="contactLeave_banner">
        <img src="@/assets/img/contact/bg-ly.jpg" />
      </div>
      <div class="contactLeave_message">
        <div class="contactLeave_messageTitle">
          <div>给我们留言</div>
          <div></div>
        </div>
        <div class="contactLeave_messageInput">
          <div>
            <div>
              <input type="text" placeholder="姓名" />
            </div>
            <div>
              <input type="text" placeholder="电话" />
            </div>
            <div>
              <input type="text" placeholder="邮箱" />
            </div>
          </div>
          <div>
            <input type="text" placeholder="地址" />
          </div>
          <div>
            <textarea placeholder="输入留言内容"></textarea>
          </div>
        </div>
      </div>
    </div> -->
    <!-- 加入我们 -->
    <div class="contactJoin">
      <div class="contactMap_title">
        <div>JOIN US</div>
        <div>
          <div>加入我们</div>
          <div class="columnTitle_bg">
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
      <div class="contactJoin_info">
        <div>
          <div>
            薪资待遇
            <span class="line"></span>
          </div>
          <div>
            <div>
              <div></div>
              <div>根据国家有关部门规定，公司为正式员工按月缴纳五险一金。</div>
            </div>
            <div>
              <div></div>
              <div>年终为员工发放年终奖。</div>
            </div>
            <div>
              <div></div>
              <div>为员工发放全勤奖励。</div>
            </div>
            <div>
              <div></div>
              <div>每个工作日为员工提供交通及就餐补助。</div>
            </div>
            <div>
              <div></div>
              <div>按国家法定节假日放假。</div>
            </div>
            <div>
              <div></div>
              <div>有生日礼品、传统节日礼品、新年利是等福利发放。</div>
            </div>
            <div>
              <div></div>
              <div>定期举行聚餐旅游等活动。</div>
            </div>
            <div>
              <div></div>
              <div>为员工提供带薪年假。</div>
            </div>
          </div>
        </div>
        <div>
          <div>
            工作环境
            <span class="line"></span>
          </div>
          <div>
            <div>
              <div></div>
              <div>位于广州科学城、空气清新，环境安静，办公场地宽敞舒适。</div>
            </div>
            <div>
              <div></div>
              <div>办公楼隔壁就是园内员工就餐区。</div>
            </div>
            <div>
              <div></div>
              <div>公司配有茶水、咖啡、零食、下午茶等供员工食用。</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      markerPoint: { lng: 113.448664, lat: 23.175464 },
      show: true,
    };
  },
  methods: {
    infoWindowClose() {
      this.show = false;
    },
    infoWindowOpen() {
      this.show = true;
    },
  },
};
</script>

<style scope>
/* 联系我们-首图 */
.contactBanner img {
  width: 100%;
  height: 300px;
  display: flex;
  object-fit: cover;
}
/* 地图 */
.contactMap {
  width: 1440px;
  margin: 100px auto;
}

.contactMap_title {
  position: relative;
}
.contactMap_title > div:nth-of-type(1) {
  font-size: 40px;
  color: #e5e5e5;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
}
.contactMap_title > div:nth-of-type(2) {
  font-size: 30px;
  color: #002780;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}
.columnTitle_bg {
  position: relative;
  margin-top: 10px;
}
.columnTitle_bg > div:nth-of-type(1) {
  position: absolute;
  width: 60px;
  height: 5px;
  background-color: #000180;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.columnTitle_bg > div:nth-of-type(2) {
  width: 200px;
  height: 1px;
  background-color: #000;
}

.contactMap_info {
  margin: 80px 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.contactMap_info img {
  width: 60px;
  height: 60px;
  display: flex;
  object-fit: cover;
  margin-right: 20px;
}
.contactMap_info > div {
  font-size: 30px;
  color: #000080;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.map {
  width: 1440px;
  height: 600px;
}
.mapTips {
  width: 300px;
  height: 100px;
}
.mapTips > div:nth-of-type(1) {
  color: #333333;
  font-size: 18px;
  font-weight: 600;
}
.mapTips > div:nth-of-type(2) {
  color: #999999;
  font-size: 16px;
  line-height: 26px;
}
/* 给我们留言 */
.contactLeave {
  position: relative;
}
.contactLeave_banner img {
  width: 100%;
  height: 680px;
  display: flex;
  object-fit: cover;
}
.contactLeave_message {
  position: absolute;
  top: 50%;
  left: 50%;
  color: #fff;
  transform: translate(-50%, -50%);
}
.contactLeave_messageTitle {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.contactLeave_messageTitle > div:nth-of-type(1) {
  font-size: 40px;
  color: #ffffff;
}
.contactLeave_messageTitle > div:nth-of-type(2) {
  margin-top: 20px;
  width: 60px;
  height: 6px;
  background: #ffffff;
}
/* 给我留言-输入框 */
.contactLeave_messageInput {
  width: 960px;
  margin-top: 60px;
}
.contactLeave_messageInput input {
  outline: none;
  color: #fff;
  border: 1px solid #fff;
  padding: 14px 20px;
  box-sizing: border-box;
  background-color: #ffffff1c;
}
input::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #aaaaaa;
}
input:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  font-size: 14px;
  color: #aaaaaa;
}
input::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  font-size: 14px;
  color: #aaaaaa;
}
input:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  font-size: 14px;
  color: #aaaaaa;
}
textarea::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #aaaaaa;
}
textarea:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  font-size: 14px;
  color: #fff;
}
textarea::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  font-size: 14px;
  color: #fff;
}
textarea:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  font-size: 14px;
  color: #fff;
}
.contactLeave_messageInput > div:nth-of-type(1) {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.contactLeave_messageInput > div:nth-of-type(1) input {
  width: 280px;
  height: 48px;
  font-size: 24px;
}
.contactLeave_messageInput > div:nth-of-type(2) {
  width: 100%;
  margin: 40px 0;
}
.contactLeave_messageInput > div:nth-of-type(2) input {
  width: 100%;
  height: 48px;
  font-size: 24px;
}
.contactLeave_messageInput > div:nth-of-type(3) {
  width: 100%;
}
.contactLeave_messageInput > div:nth-of-type(3) textarea {
  width: 100%;
  height: 180px;
  font-size: 24px;
  border: 1px solid #fff;
  background-color: #ffffff1c;
  padding: 14px 20px;
  color: #fff;
  outline: none;
  box-sizing: border-box;
}
/* 环境与福利 */
.contactJoin {
  margin: 100px auto;
  width: 1440px;
}
.contactJoin_info {
  margin-top: 120px;
  display: flex;
  justify-content: space-between;
}
.contactJoin_info > div > div:nth-of-type(1) {
  width: 180px;
  height: 40px;
  line-height: 30px;
  background-color: #000080;
  color: #fff;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.line {
  position: absolute;
  bottom: -2px;
  right: -10%;
  width: 60%;
  height: 4px;
  background-color: #6a9ecd;
}
.contactJoin_info > div > div:nth-of-type(2) {
  padding: 60px 0 0 60px;
  font-size: 18px;
  color: #9E9E9E;
}
.contactJoin_info > div > div:nth-of-type(2) > div {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 20px 0;
}
.contactJoin_info > div > div:nth-of-type(2) > div > div:nth-of-type(1) {
  width: 10px;
  height: 10px;
  background-color: #9E9E9E;
  margin-right: 20px;
  transform: rotate(45deg);
}
</style>